const requireComponent = require.context('./', false, /\w+\.vue$/)

const comps = {}

// eslint-disable-next-line array-callback-return
requireComponent.keys().map(fileName => {
	const comp = requireComponent(fileName).default
	comps[comp.name] = comp
})

// console.log(comps, 78787878)

export default comps
